/* eslint-disable */
// This file is generated by dev/generateInitialLangFallback.ts. Do not edit it manually.

export default {
  "WrongNumber": "Wrong number?",
  "SentAppCode": "We've sent the code to the **Telegram** app on your other device.",
  "LoginJustSentSms": "We've sent you a code via SMS. Please enter it above.",
  "LoginHeaderPassword": "Enter Password",
  "LoginEnterPasswordDescription": "You have Two-Step Verification enabled, so your account is protected with an additional password.",
  "StartText": "Please confirm your country codenand enter your phone number.",
  "LoginPhonePlaceholder": "Your phone number",
  "LoginNext": "Next",
  "LoginQRLogin": "Log in by QR Code",
  "LoginQRTitle": "Log in to Telegram by QR Code",
  "LoginQRHelp1": "Open Telegram on your phone",
  "LoginQR2Help2": "Go to **Settings** > **Devices** > **Link Desktop Device**",
  "LoginQRHelp3": "Point your phone at this screen to confirm login",
  "LoginQRCancel": "Log in by phone Number",
  "YourName": "Your Name",
  "LoginRegisterDesc": "Enter your name and add a profile photo.",
  "LoginRegisterFirstNamePlaceholder": "First Name",
  "LoginRegisterLastNamePlaceholder": "Last Name",
  "LoginSelectCountryTitle": "Country",
  "CountryNone": "Country not found",
  "PleaseEnterPassword": "Enter your new password"
} as Record<string, string>;
